<template>
  <div>
    <title-bar title="Galerien">
      <router-link slot="right" to="/gallery/new" class="button is-primary">
        <b-icon
          :icon="$func.getIcon('new').icon"
          :pack="$func.getIcon('new').pack"
          class="mr-1"
        />
        Neue Galerie anlegen
      </router-link>
    </title-bar>
    <section class="section is-main-section">
      <card-component class="has-table has-mobile-sort-spaced">
        <div>
          <trash-box
            :is-active="isModalActive"
            :trash-object-name="trashObjectName"
            @confirm="trashConfirm"
            @cancel="trashCancel"
          />
          <b-table
            :checked-rows.sync="checkedRows"
            :checkable="checkable"
            :loading="isLoading"
            :paginated="paginated"
            :per-page="perPage"
            :striped="true"
            :hoverable="true"
            :sort-icon="$func.getIcon('sort-up').icon"
            :sort-icon-pack="$func.getIcon('sort-up').pack"
            default-sort="name"
            :data="users"
          >
            <b-table-column
              label="Name"
              field="name"
              sortable
              searchable
              v-slot="props"
            >
              <router-link
                :to="{
                  name: 'gallery.edit',
                  params: { id: props.row.id }
                }"
              >
                {{ props.row.name }}
              </router-link>
            </b-table-column>

            <b-table-column
              label="Anzahl Bilder"
              field="num_photos"
              sortable
              v-slot="props"
              width="10rem"
            >
              {{ props.row.num_photos }}
            </b-table-column>

            <b-table-column
              label="Angelegt am"
              field="created_at"
              sortable
              v-slot="props"
              width="15rem"
            >
              {{ props.row.created_at | moment() }} Uhr
            </b-table-column>

            <b-table-column
              label="Deaktiviert"
              field="disable"
              sortable
              v-slot="props"
              width="8rem"
            >
              {{ props.row.disable ? "Ja" : "Nein" }}
            </b-table-column>

            <b-table-column
              custom-key="actions"
              cell-class="is-actions-cell"
              v-slot="props"
              width="8rem"
            >
              <div class="buttons is-right">
                <router-link
                  :to="{ name: 'gallery.edit', params: { id: props.row.id } }"
                  class="button is-small is-primary"
                >
                  <b-icon
                    :icon="$func.getIcon('edit').icon"
                    :pack="$func.getIcon('edit').pack"
                    size="is-small"
                  />
                </router-link>
                <button
                  class="button is-small is-danger"
                  type="button"
                  @click.prevent="trashModal(props.row)"
                >
                  <b-icon
                    :icon="$func.getIcon('delete').icon"
                    :pack="$func.getIcon('delete').pack"
                    size="is-small"
                  />
                </button>
              </div>
            </b-table-column>

            <section slot="empty" class="section">
              <div class="content has-text-grey has-text-centered">
                <template v-if="isLoading">
                  <p>Hole Daten...</p>
                </template>
                <template v-else>
                  <p>
                    <b-icon
                      :icon="$func.getIcon('sad').icon"
                      :pack="$func.getIcon('sad').pack"
                      size="is-large"
                    />
                  </p>
                  <p>Nichts gefunden&hellip;</p>
                </template>
              </div>
            </section>
          </b-table>
        </div>
      </card-component>
    </section>
  </div>
</template>

<script>
import api from "@/utils/api.js";
import CardComponent from "@/components/CardComponent";
import TitleBar from "@/components/TitleBar";
import TrashBox from "@/components/Modals/TrashBox";

export default {
  name: "Galleries",
  components: {
    TitleBar,
    CardComponent,
    TrashBox
  },

  props: {
    dataUrl: {
      type: String,
      default: null
    },
    checkable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isModalActive: false,
      trashObject: null,
      users: [],
      isLoading: false,
      paginated: false,
      perPage: 15,
      checkedRows: []
    };
  },
  computed: {
    trashObjectName() {
      if (this.trashObject) {
        return this.trashObject.name;
      }

      return null;
    }
  },
  watch: {
    $route() {
      this.getData();
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.isLoading = true;
      api
        .get("/galleries?top=false")
        .then(r => {
          if (r.data) {
            if (r.data.length > this.perPage) {
              this.paginated = true;
            }
            this.users = r.data;
          }
        })
        .finally(() => (this.isLoading = false));
    },
    trashModal(trashObject) {
      this.trashObject = trashObject;
      this.isModalActive = true;
    },
    trashConfirm() {
      this.isModalActive = false;
      api.delete(`/galleries/${this.trashObject.id}`).then(() => {
        this.getData();
        this.$buefy.snackbar.open({
          message: "Galerie gelöscht",
          queue: false
        });
      });
    },
    trashCancel() {
      this.isModalActive = false;
    }
  }
};
</script>
